<template>
  <v-container
    id="faq"
    fluid
    tag="section"
  >
    <v-row
      justify="center"
    >
      <v-col
        cols="8"
      >
        <base-material-card
          icon="mdi-frequently-asked-questions"
          class="px-3 py-3"
          color="#FFC107"
        >
          <br>
          <v-row>
            <v-col
              cols="12"
            >
              <v-expansion-panels
                popout
                focusable
                :elevation="0"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h2 class="font-weight-medium align-center mt-3 mb-3">
                      Dashboard
                    </h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Se um cliente ficar online a página será atualizada automaticamente?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        O quadro de clientes Meu Clinic se atualiza automaticamente a cada 3 (três) minutos, porém é possível forçar a atualização clicando no botão Atualizar presente no canto superior direito, em frente à "Clientes Meu Clinic", ou simplesmente atualizar a página em seu navegador. Isto fará com que o algorítimo de monitoramento re-avalie todos os clientes.
                      </h3>
                    </v-alert>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="warning"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Por que o cliente consta como offline?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Caso o cliente apareça na seção offline, significa que o programa Meu Clinic em seu servidor não está respondendo aos testes de conexão que são efetuados constantemente pelo algorítimo de monitoramento. Isto pode ocorrer por diversos motivos, dentre eles:
                        <ul>
                          <li>O servidor pode estar desligado.</li>
                          <li>O servidor pode estar sem conexão com a internet.</li>
                          <li>O programa Meu Clinic pode estar fechado.</li>
                          <li>O programa Meu Clinic pode não estar atualizado.</li>
                        </ul>
                      </h3>
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h2 class="font-weight-medium align-center mt-3 mb-3">
                      Servidores AWS
                    </h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        A página atualiza automaticamente?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Não, a página somente se atualiza ao clicar no botão Atualizar presente na parte superior da tela, ou simplesmente atualizando a página em seu navegador.
                      </h3>
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h2 class="font-weight-medium align-center mt-3 mb-3">
                      Clientes com serviço WEB
                    </h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        A página atualiza automaticamente?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Não, a página somente se atualiza ao clicar no botão Atualizar presente na parte superior da tela, ou simplesmente atualizando a página em seu navegador.
                      </h3>
                    </v-alert>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Como vejo detalhes do cadastro de um cliente?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Para ver os detalhes de cadastro de um cliente basta clicar no ícone <v-icon>mdi-information</v-icon>, uma expansão aparecerá exibindo as informações.
                      </h3>
                    </v-alert>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Como cadastro um Cliente?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Para cadatrar um cliente, é necessário apenas clicar no botão Novo Cliente que se encontra no fim da listagem de clientes. Um diálogo aparecerá com o formulário de cadastro; o formulário possuí campos obrigatórios, e campos opcionais, mas é recomendado que sejam preenchidos o máximo de campos possíveis.
                        <br>
                        <br>
                        Após preencher o formulário clique em Enviar, será mostrada um diálogo de confirmação e logo em seguida você será enviado para a página de sucesso, onde poderá efetuar o download dos arquivos de configuração (config.json) e autenticação (auth.json). Pronto! O cliente está cadastrado.
                      </h3>
                    </v-alert>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Como edito o cadastro de um cliente?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Para editar o cadatro de um cliente, é necessário apenas clicar no botão Editar que se encontra no painel de expansão que se deseja editar. Você será redirecionado para o formulário de edição; o formulário possuí campos obrigatórios, e campos opcionais, mas é recomendado que sejam preenchidos o máximo de campos possíveis.
                        <br>
                        <br>
                        Após preencher o formulário clique em Enviar, será mostrada uma confirmação e você será redirecionado para a lista de Clientes com Serviço WEB. Pronto! O cliente foi editado.
                      </h3>
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel><v-expansion-panel>
                  <v-expansion-panel-header>
                    <h2 class="font-weight-medium align-center mt-3 mb-3">
                      Arquivos AWS
                    </h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Como usar o Arquivos AWS?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        A página Arquivos AWS serve para gerenciar arquivos importantes. Nela podemos fazer download (baixar o arquivo na máquina), upload (subir o arquivo para o servidor que hospeda nossos arquivos) e também podemos deletar arquivos desse servidor.
                      </h3>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Para Baixar 1 arquivo, busque o arquivo pelo nome através da barra de buscas ou descendo a página e marque sua caixa, clicando em cima dela (um símbolo colorido aparece na caixa com um check). Depois, arraste a página e clique no botão, com o nome do arquivo em cima, que diz 'Baixar'. Se houverem múltiplos arquivos selecionados, e apenas quiser baixar um, clique no 'Baixar' do arquivo com o nome correto.
                      </h3>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Para Baixar múltiplos arquivos, selecione suas caixas e clique no botão 'Baixar Todos'.
                      </h3>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Para Subir 1 ou múltiplos arquivos, clique no botão 'Upload' localizado abaixo da barra de buscas. Um diálogo aparecerá com um campo para escolha de arquivos e uma seleção de Diretório. Clique na barra de seleção de arquivos e escolha qual(is) arquivo(s) deseja subir. Em seguida, clique na caixa que contém a pasta para a qual deseja subir o arquivo (apenas 1 pasta é selecionável por vez). Ao escolher a pasta e os arquivos, aparecerá um botão no canto inferior direito do diálogo para enviar os arquivos. Clique nele e o arquivo será enviado.
                      </h3>
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h2 class="font-weight-medium align-center mt-3 mb-3">
                      Usuários
                    </h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Como busco por um usuário?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Para efetuar uma busca por um usuário específico basta digitar o Nome ou Email do usuário cadastrado, estes devem ser escritos exatamente da mesma forma como foram cadastrados respeitando letras maíusculas, minúsculas, números, símbolos e espaços. Após isso basta clicar em  e o sistema se encarregará de efetuar a busca. O resultado será exibido abaixo do formulário de pesquisa.
                      </h3>
                    </v-alert>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Efetuei a busca e foram retornados vários usuários. Qual deles é o certo?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        O mecanismo de busca exibe todos os usuários que possuam o mesmo Nome ou Email cadastrados, para identificar o usuário desejado é preciso verificar os resultados da busca por outras informações do usuário desejado, tais como CPF, data de nascimento, telefone, dentre outros.
                      </h3>
                    </v-alert>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Efetuei uma busca mas nenhum usuário foi encontrado. O que eu faço?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        É possível que o nome buscado não tenha sido digitado corretamente conforme se encontra no banco de dados, ou o usuário buscado não possui cadastro no banco de dados.
                      </h3>
                    </v-alert>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        Preenchi o formulário de busca e cliquei em buscar porém nada aconteceu. O que eu faço?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        É possível que as credenciais de acesso à plataforma que são geradas no login tenha expirado. Neste caso navegue até o menu, clique em <v-icon>mdi-logout</v-icon> e efetue o login novamente. Novas credenciais serão geradas e a navegação ocorrerá normalmente.
                      </h3>
                    </v-alert>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="error"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        É possível recuperar um usuário deletado?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        <strong>NÃO É POSSÍVEL RECUPERAR UM USUÁRIO DELETADO.</strong> Uma vez deletado o registro é apagado do banco de dados.
                      </h3>
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h2 class="font-weight-medium align-center mt-3 mb-3">
                      Dúvidas gerais
                    </h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <v-alert
                      outlined
                      prominent
                      type="info"
                      text
                    >
                      <h2 class="font-weight-medium align-center mt-3 mb-3">
                        O que é 'Dark mode'?
                      </h2>
                      <h3 class="font-weight-regular align-center mt-3 mb-3">
                        Dark mode é uma opção de tema do site, que troca o fundo branco por um fundo escuro e o texto em preto por texto em branco. Usar Dark mode ou não é opcional de cada um e não afeta o funcionamento de nenhum componente do site, é apenas estético.
                      </h3>
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'FAQ',
  }
</script>
